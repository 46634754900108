import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpService } from '../service/http/http.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {

  private _sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private _http: HttpService
  ) { }


  ngOnInit(): void {
    const paramsSuber = this.route.queryParams
      .subscribe(params => {
        const sessionId = params['sessionId'];
        console.log(params);
      });

    this._sub.add(paramsSuber);
  }


  getLastMessage() {

  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

}
