<div class="structure-main han-structure">
    <div class="file-number font-12 key-value-wrap">
        <div class="wrap-label wrap-label-two">
            <span>檔</span>
            <span>號：</span>
        </div>
        <div class="wrap-value">
            7893247984v89328
        </div>
    </div>
    <div class="year-saving font-12 key-value-wrap">
        <div class="wrap-label wrap-label-two">
            保存年限：
        </div>
        <div class="wrap-value">
            16year
        </div>
    </div>
    <div class="han-sender-wrap font-26">
        <div class="han-sender">
            國家發展委員會
        </div>
        <div class="structure-type">函</div>
    </div>
    <div class="han-address-from-wrap font-16">
        <div class="wrap-label letter-spacing-4">
            地址：
        </div>
        <div class="wrap-value letter-spacing-4">
            10020 臺北市中正區寶慶路3號
        </div>
    </div>
    <div class="han-address-from-wrap font-16">
        <div class="wrap-label">
            聯絡方式：
        </div>
        <div class="wrap-value">
            (承辦人、電話、傳真、e-mail)
        </div>
    </div>

    <div class="main-content">
        <div class="han-address-to-wrap han-address-to-postcode-wrap font-16">
            <div class="wrap-value">
                10346
            </div>
        </div>
        <div class="han-address-to-wrap font-16">
            <div class="wrap-value">
                臺北市大同區延平北路 2 段 83 號 9 樓
            </div>
        </div>
        <div class="han-receiver-wrap font-20 letter-spacing-2">
            <div class="wrap-label">
                受文者：
            </div>
            <div class="wrap-value">
                勞動部
            </div>
        </div>
        <div class="han-publish-date-wrap font-16">
            <div class="wrap-label">
                發文日期：
            </div>
            <div class="wrap-value">
                中華民國113年9月23日
            </div>
        </div>
        <div class="han-doc-number-wrap font-16">
            <div class="wrap-label">
                發文字號：
            </div>
            <div class="wrap-value">
                ○○字第 0000000000 號
            </div>
        </div>
        <div class="han-post-type-wrap font-16">
            <div class="wrap-label">
                發文字號：
            </div>
            <div class="wrap-value">
                ○○字第 0000000000 號
            </div>
        </div>
        <div class="han-security-level-wrap font-16">
            <div class="wrap-label">
                密等及解密條件或保密期限：
            </div>
            <div class="wrap-value">
            xxxx
            </div>
        </div>
        <div class="han-attachment-wrap font-16">
            <div class="wrap-label">
                附件：
            </div>
            <div class="wrap-value">
            議程資料
            </div>
        </div>
        
        <div class="han-purpose-wrap font-18">
            <div class="wrap-label">
                主旨：
            </div>
            <div class="wrap-value">
                請派員踴躍參加「2015 網際網路趨勢研討會」，並轉知所 屬，請查照。
            </div>
        </div>
        <div class="han-instructions-wrap font-18">
            <div class="wrap-label letter-spacing-4">
                說明：
            </div>
            <div class="wrap-value letter-spacing-4 value-with-order">
                一、依據鈞院綜合業務處本(104)年 3 月 25 日院臺綜字第1040128150 號函決議事項(四)辦理(詳附件 1)。
            </div>
            <div class="wrap-value letter-spacing-4 value-with-order">
                二、文書及檔案管理電腦化作業規範(以下簡稱文檔規範)前於 本年 2 月 2 日以院授發檔(資)字第 1040008032 號函頒修 正，因應鈞院本年 4 月 28 日院臺綜字第 1040130453 號函 頒修正「文書處理手冊」，內容增列會勘通知單、限期公 文無須填列速別等規定，須配合再予修正，以提供機關增
                修文檔資訊系統之依據。
            </div> 
            <div class="wrap-value letter-spacing-4 value-with-order">
                三、檢陳文檔規範(修正草案)總說明、修正草案對照表及修正草案(詳附件 2、3、4)。 
            </div>
        </div>
        <!-- <div class="han-to-do font-18 main-key-value-wrap ">
            <div class="wrap-label letter-spacing-4">
                擬辦：
            </div>
            <div class="wrap-value letter-spacing-4">
                本案如奉核可，擬由本會代擬代判院稿，分行各機關作為文書及檔案管理電腦化作業之依據。<br>敬請秘書長轉陳副院長 院長
            </div>
        </div> -->
    </div>
</div>
