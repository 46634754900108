import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { delay, Subscription } from 'rxjs';
import { MessageService } from 'src/app/service/entity/message/message.service';
import { ChatRes } from 'src/app/service/http/http.interface';
import { HttpService } from 'src/app/service/http/http.service';

@Component({
  selector: 'app-article-qian',
  templateUrl: './article-qian.component.html',
  styleUrls: ['./article-qian.component.scss']
})
export class ArticleQianComponent implements OnInit, OnDestroy {

  private _sub: Subscription = new Subscription();

  chatRes!: ChatRes;

  constructor(
    private route: ActivatedRoute,
    private _http: HttpService,
    private _messageS: MessageService
  ) {}

  ngOnInit(): void {
    //測試用model 
    // this.chatRes = this._messageS.fakeChatRes();

    //正式用抓api的model
    this.subscribeStream();
  }

  subscribeStream() {
    const paramsSuber = this.route.queryParams
      .subscribe(params => {
        const sessionId = params['sessionId'];
        if (!!sessionId) this.getLastMessage(sessionId);
        console.log(params);
      });
    this._sub.add(paramsSuber);
  }

  getLastMessage(sessionId: string) {
    this._http.getLastMessage(sessionId)
      .pipe(
      // delay(3000)
    )
      .subscribe(
        (chatRes: ChatRes) => {
          this.chatRes = chatRes
          // console.log("chatRes---> ", chatRes)
        }
      );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }
}
