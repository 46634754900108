import { Component, effect, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from '../service/entity/message/message.service';
import { Message, PreviewFile } from '../service/entity/message/message.model';
import { Router } from '@angular/router';
import { SocketService } from '../service/socket/socket.service';
import { Subscription } from 'rxjs';
// interface Message {
//   text: string;
//   sent: boolean;
// }

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  private _sub: Subscription = new Subscription();

  messages: Message[] = [
    // { content: '你好！有什麼我可以幫忙的嗎?', sent: false, done: 1 }
  ];

  constructor(
    private _ngZone: NgZone,
    private _messageS: MessageService,
    private _router: Router,
    private _socketS: SocketService
  ) {
    effect(() => {
      let messageList = this._messageS.messageListSignal();
      // 使用 filter 過濾掉 system.show 為 true 且不是最後一筆的資料
      messageList = messageList.filter((message, index) => {
        // 保留最後一筆資料
        const isLastMessage = index === messageList.length - 1;

        // 如果不是最後一筆資料，且 system.show 為 true，則移除該資料
        if (!isLastMessage && message.payload?.system?.is_show_progress) {
          return false;
        }

        // 保留其他資料
        return true;
      });
      console.log('messageList~~~~~~~~~~~~', messageList)

      this.messages = messageList;
      this.scrollToBottom();
    });
  }
  ngOnInit(): void {
    // this._socketS.connect()
    this.subscribeStream();
  }


  subscribeStream() {
    const resultSuber = this._socketS.resultSubject
      .subscribe((res: any) => {
        // socket 來的東西
        // console.log(res)
        // switch (res.event) {
        //   case 'join':
        //     console.log('join', res.data['sid'])
        //     this._messageS.setSessionId(res.data['sid'])
        //     break;
        // }
        // this._messageS
      });

    this._sub.add(resultSuber);
  }

  onMessageSend(payload: { message: string, previewFileList: PreviewFile[] }) {
    this.createMessage(payload.message, true, 0, payload.previewFileList);
    // this.fakeReply();
  }
  onFileSelected(event: { fileType: string, file: File }) {

    let text!: string;

    switch (event.fileType) {
      case "pdf":
        text = `上傳PDF: ${event.file.name}`;
        break;

      case "image":
        text = `上傳圖片: ${event.file.name}`;
        break;

      default:
        break;
    }

    // this.createMessage(text, false);
  }

  scrollToBottom() {
    const messagesContainer = document.getElementById('messagesContainer');
    if (messagesContainer) {
      setTimeout(() => {
        messagesContainer.scrollTop = messagesContainer.scrollHeight
      }, 100);
    }
  }

  createMessage(text: string, sent: boolean, reqType: number, previewFileList: PreviewFile[] = []) {
    // this.messages.push({ text, sent });
    const message: Message = { content: text, sent, done: 1, type: 'text' };
    this._messageS.postMessage(message, reqType, previewFileList);
    // this.scrollToBottom();
  }

  fakeReply() {
    setTimeout(() => {
      // this.messages.push({ text: '這是一個自動回覆。', sent: false });
      this.createMessage("這是一個自動回覆。", false, 0)
    }, 1000);
  }

  goToHome() {
    this._router.navigate(['home']);
  }
  test() {
    const messagesContainer: any = document.getElementById('messagesContainer');

    console.log("scrollTop--->", messagesContainer?.scrollTop)
    console.log("scrollHeight--> ", messagesContainer?.scrollHeight)

    messagesContainer.scrollTop = messagesContainer?.scrollHeight as number
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

}
