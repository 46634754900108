<button class="back-btn" (click)="goToHome()">
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
    <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
  </svg>
  返回
</button>
<div class="chat-container">
  <div id='messagesContainer' class="messages-container">
    <div *ngFor="let message of messages" class="message" [ngClass]="{'sent': message.sent, 'received': !message.sent}">

      <!-- 自己送出的訊息 -->
      <ng-container *ngIf="message.sent">
        <div *ngIf="message.type === 'text'">
          {{ message.content }}
        </div>

        <div *ngIf="message.type === 'file'">
          <img style="width: 40px" [src]="message.url">
          {{ message.content }}
        </div>
      </ng-container>

      <!-- 收到的訊息 -->
      <ng-container *ngIf="!message.sent">
        <app-received-msg [message]="message"></app-received-msg>
      </ng-container>
    </div>
  </div>


  <div style="margin-top: 10px;">
    <!-- <app-chat-input></app-chat-input> -->
    <app-input (onMessageSend)="onMessageSend($event)" (onFileSelected)="onFileSelected($event)"></app-input>
  </div>

</div>