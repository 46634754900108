import { Component, Input } from '@angular/core';
import { Message } from 'src/app/service/entity/message/message.model';

@Component({
  selector: 'app-received-msg-sign',
  templateUrl: './received-msg-sign.component.html',
  styleUrls: ['./received-msg-sign.component.scss']
})
export class ReceivedMsgSignComponent {
  @Input('message') recMessage!: Message
  constructor(){
    console.log('recMessage---> ',this.recMessage)
  }

  openPreviewPage() {
    const sessionId = this.recMessage?.payload?.session_id
    const url = "preview/qian"
    const queryParams = `?sessionId=${sessionId}`;
    // console.log('sessionId---> ', sessionId)
    window.open(url + queryParams, '_blank');
  }
}
