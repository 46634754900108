import { Component, Input } from '@angular/core';
import { Message } from 'src/app/service/entity/message/message.model';

@Component({
  selector: 'app-received-msg-letter',
  templateUrl: './received-msg-letter.component.html',
  styleUrls: ['./received-msg-letter.component.scss']
})
export class ReceivedMsgLetterComponent {
  @Input('message') recMessage!: Message
}
