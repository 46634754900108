<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="side" [(opened)]="isSidenavOpen">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <!-- <span>應用標題</span> -->
    </mat-toolbar>
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
