import { Component, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FileTypes, PreviewFile } from '../service/entity/message/message.model';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {

  message = '';
  acceptedFileType!: FileTypes
  isComposing: boolean = false; // 用來檢查是否處於中文輸入法的組字狀態

  previewFileList: PreviewFile[] = [];

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Output() onMessageSend = new EventEmitter<{ message: string, previewFileList: PreviewFile[] }>();
  @Output() onFileSelected = new EventEmitter<{ fileType: FileTypes, file: File }>();

  sendMessage() {
    if (this.message.trim()) {
      this.onMessageSend.emit({ message: this.message, previewFileList: this.previewFileList });
      //沒輸入文字可以上傳檔案嗎？
      console.log("file list---> ", this.previewFileList)
      console.log("message content---> ", this.message)
      console.log("call api")
      this.message = '';
    } else if (this.previewFileList.length != 0)
      this.onMessageSend.emit({ message: this.message, previewFileList: this.previewFileList });

    this.previewFileList = [];


  }

  selectFileType(type: FileTypes) {

    switch (type) {
      case "pdf":
        this.fileInput.nativeElement.accept = '.pdf';
        this.acceptedFileType = "pdf";
        break;

      case "image":
        this.fileInput.nativeElement.accept = 'image/*';
        this.acceptedFileType = "image";
        break;

      default:
        break;
    }

    if (!!this.fileInput)
      this.fileInput.nativeElement.click(); // 觸發檔案選擇器
  }

  fileSelected(event: any) {

    let fileType!: FileTypes;

    switch (this.acceptedFileType) {
      case "pdf":
        fileType = "pdf"
        break;
      case "image":
        fileType = "image"
        break;
    }

    // const file: File = event.target.files[0];
    const files: File[] = Object.values(event.target.files || {});

    files.map(file => {
      this.setPreviewFile(file, fileType);
    })
    // this.setPreviewFile(file, fileType);

    // this.onFileSelected.emit({ fileType, file })
    this.fileInput.nativeElement.value = '';
    console.log("files---> ", files)
    // if (file) {
    //   this.uploadFile(file);
    // }
  }


  setPreviewFile(file: File, fileType: FileTypes) {
    const strategy: Map<FileTypes, Function> = new Map();

    strategy.set('image', (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        const previewFile = { file, fileType, fileName: file.name, url: reader.result as string };
        this.previewFileList.push(previewFile);
      };
      reader.readAsDataURL(file);
    });

    strategy.set('pdf', (file: File) => {
      this.previewFileList.push({ file, fileType, fileName: file.name, url: 'assets/icon_pdf.png' });
    });

    (strategy.get(fileType) as Function)(file);
  }

  removePreviewFile(index: number) {
    this.previewFileList.splice(index, 1);
  }
  // 當開始組字時觸發
  onCompositionStart() {
    this.isComposing = true;
  }

  // 當組字完成時觸發
  onCompositionEnd() {
    this.isComposing = false;
  }

  // 按下 Enter 後觸發
  onEnter() {
    if (!this.isComposing) this.sendMessage();
  }

}

