

<div class="file-preview">
  <div class="preview-item" *ngFor="let item of previewFileList; let i=index">
    <img style="width: 40px" [src]="item.url">
    <div>{{item.fileName}}</div>
    <button (click)="removePreviewFile(i)">Ｘ</button>
  </div>

</div>
<div class="chat-input-container">
  <button class="upload-btn" [matMenuTriggerFor]="menu">
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
      <path
        d="M720-330q0 104-73 177T470-80q-104 0-177-73t-73-177v-370q0-75 52.5-127.5T400-880q75 0 127.5 52.5T580-700v350q0 46-32 78t-78 32q-46 0-78-32t-32-78v-370h80v370q0 13 8.5 21.5T470-320q13 0 21.5-8.5T500-350v-350q-1-42-29.5-71T400-800q-42 0-71 29t-29 71v370q-1 71 49 120.5T470-160q70 0 119-49.5T640-330v-390h80v390Z" />
    </svg>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="selectFileType('pdf')">上傳PDF</button>
    <button mat-menu-item (click)="selectFileType('image')">上傳圖片</button>
  </mat-menu>

  <input #fileInput type="file" (change)="fileSelected($event)" hidden multiple />
  <input type="text" [(ngModel)]="message" placeholder="輸入訊息" (keyup.enter)="onEnter()"
    (compositionstart)="onCompositionStart()" (compositionend)="onCompositionEnd()" [disabled]="false"/>
  <!-- <button class="submit-btn" (click)="sendMessage()">
    提交
  </button> -->
</div>


<!-- <mat-form-field class="gemini-input-dialog">
  <mat-label>输入您的问题</mat-label>
  <textarea matInput [(ngModel)]="message" (keydown.enter)="sendMessage()" placeholder="输入消息..."></textarea>
</mat-form-field>

<button mat-raised-button color="primary" (click)="sendMessage()">提交</button> -->

<!-- 
<div class="input-container">
    <input [(ngModel)]="message" (keydown.enter)="sendMessage()" placeholder="输入消息..." />
    <button (>发送</button>
  </div> -->