<div>
    {{ recMessage.content }}
</div>
<div *ngIf="recMessage?.payload?.system?.is_show_progress">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="recMessage?.payload?.system?.is_show_doc">
    <br>
    <!-- 簽 -->
    <app-received-msg-sign *ngIf="recMessage?.payload?.system?.doc_type =='簽' || recMessage?.payload?.system?.doc_type =='府簽'"
        [message]="recMessage"></app-received-msg-sign>
    <!-- 函 -->
    <app-received-msg-letter *ngIf="recMessage?.payload?.system?.doc_type =='函'"
        [message]="recMessage"></app-received-msg-letter>
</div>