import { Component } from '@angular/core';

@Component({
  selector: 'app-article-han',
  templateUrl: './article-han.component.html',
  styleUrls: ['./article-han.component.scss']
})
export class ArticleHanComponent {

}
