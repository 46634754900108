import { Injectable } from '@angular/core';
import { ServerSocket } from './socket.event';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { MessageService } from '../entity/message/message.service';
import { Message } from '../entity/message/message.model';
import { ChatRes } from '../http/http.interface';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  resultSubject = new Subject

  private _socket: ServerSocket = io(environment.socketUrl, {
    autoConnect: false,
    path: '/sockets'
  });

  constructor(private _messageS: MessageService) {
    this._listenEvents();
    this.connect();
  }

  private _listenEvents() {
    this._socket.on('chat', (chatRes: ChatRes) => {
      console.log('socket Event ====>', chatRes)
      const receivedMsg: Message = {
        content: chatRes.system.content,
        sent: false,
        done: chatRes.system.done,
        type: 'text',
        payload: chatRes
      }
      this._messageS.addMessage(receivedMsg);
    });
    
    this._socket.on('join', (sid: any) => {
      this._messageS.setSessionId(sid['sid']);
      console.log('socket Event ====>', sid)
      // this.resultSubject.next({data: payload, event: 'join'})
    });
  }

  emit(event: string, payload: any) {
    (this._socket as any).emit(event, payload);
  }

  connect() {
    this._socket.connect();
  }



}
