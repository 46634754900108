import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChatComponent } from './chat/chat.component';
import { TestComponent } from './test/test.component';
import { ArticleHanComponent } from './article-structure/article-han/article-han.component';
import { ArticleQianComponent } from './article-structure/article-qian/article-qian.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', component: HomeComponent },
  { path: 'chat', component: ChatComponent },
  {path:'preview/han',component:ArticleHanComponent},
  {path:'preview/qian',component:ArticleQianComponent}
  // { path: 'preview', component: TestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
