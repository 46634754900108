<ng-container *ngIf="!!recMessage?.payload?.system">

    <div *ngIf="recMessage?.payload?.system?.is_doc_done">
        {{recMessage?.payload?.system?.doc_type}} 於
        {{recMessage?.payload?.system?.doc_unit_head}}
    </div>
    <br>
    <div *ngIf="recMessage?.payload?.system?.is_doc_done">
        檔號: {{recMessage?.payload?.system?.doc_code}}
        <br>
        保存年限: {{recMessage?.payload?.system?.doc_keep_time}}
        <br>
        日期: {{recMessage?.payload?.system?.doc_datetime}}
    </div>
    <br>
    <div>
        主旨: <br>
        {{recMessage?.payload?.system?.doc_title}}
    </div>
    <br>
    <div *ngIf="recMessage?.payload?.system?.doc_description_item_list?.length">
        說明: <br>
        {{recMessage?.payload?.system?.doc_description_content}}
        <br *ngIf="!!recMessage?.payload?.system?.doc_description_content">
        <div *ngFor="let description of recMessage?.payload?.system?.doc_description_item_list">
            {{description}}
        </div>
    </div>
    <br>
    <div *ngIf="recMessage?.payload?.system?.doc_actions">
        擬辦:<br>
        {{recMessage?.payload?.system?.doc_actions}}
    </div>
    <br>
    <div  *ngIf="recMessage?.payload?.system?.is_doc_done">
        <!-- <button class="preview-btn">
            詳細預覽公文
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                <path
                    d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
            </svg>
        </button> -->
        <button mat-flat-button [color]="'primary'" (click)="openPreviewPage()">詳細預覽公文</button>
    </div>

    <!-- <mat-form-field  *ngIf="recMessage?.payload?.system?.doc_title">
        <mat-label>主旨</mat-label>
        <input matInput [value]="recMessage?.payload?.system?.doc_title">
    </mat-form-field> -->


</ng-container>