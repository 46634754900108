
<div class="structure-main qian-structure" [hidden]="!chatRes">
    <div class="file-number font-12 key-value-wrap">
        <div class="wrap-label wrap-label-two">
            <span>檔</span>
            <span>號：</span>
        </div>
        <div class="wrap-value">
            {{chatRes?.system?.doc_code}}
        </div>
    </div>
    <div class="year-saving font-12 key-value-wrap">
        <div class="wrap-label wrap-label-two">
            保存年限：
        </div>
        <div class="wrap-value">
            {{chatRes?.system?.doc_keep_time}}
        </div>
    </div>
    <div class="file-number font-12 key-value-wrap">
        <div class="wrap-label wrap-label-two">
            <span>日</span>
            <span>期：</span>
        </div>
        <div class="wrap-value">
            {{chatRes?.system?.doc_datetime}}
        </div>
    </div>

    <div class="qian-receiver-wrap">
        <div class="font-26">{{chatRes?.system?.doc_type}}</div>
        <div class="qian-receiver">
            <span class="font-24">於</span>
            <span class="font-24">
                {{chatRes?.system?.doc_unit_head}}
            </span>
        </div>
    </div>
    <div class="main-content">
        <div class="qian-purpose-wrap font-18 main-key-value-wrap ">
            <div class="wrap-label letter-spacing-4">
                主旨：
            </div>
            <div class="wrap-value letter-spacing-4 ">
                {{chatRes?.system?.doc_title}}
            </div>
        </div>
    
        <div class="qian-instructions-wrap font-18">
            <div class="wrap-label letter-spacing-4">
                說明：
            </div>
            <div class="wrap-value letter-spacing-4 value-with-margin">
                {{chatRes?.system?.doc_description_content}}
            </div>
            <div class="wrap-value letter-spacing-4 value-with-order" *ngFor="let description of chatRes?.system?.doc_description_item_list">
                {{description}}
            </div> 
          
        </div>
        <div class="qian-to-do font-18 main-key-value-wrap ">
            <div class="wrap-label letter-spacing-4">
                擬辦：
            </div>
            <div class="wrap-value letter-spacing-4">
                {{chatRes?.system?.doc_actions}}
            </div>
        </div>
    </div>

</div>