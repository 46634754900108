import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostChatReq, ChatRes } from './http.interface';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient) { }


  postChat(req: PostChatReq) {

    const formData = new FormData();
    formData.append('session_id', req.sessionId);
    formData.append('content', req.content);
    formData.append('type', `${req.type}`);
    req.fileList.map((file: File) => {
      formData.append('files', file);
    });

    return this._http.post<ChatRes>(`${environment.httpUrl}/chat`, formData)
  }

  getLastMessage(sessionId: string) {
    const params = new HttpParams().append('session_id', sessionId);
    return this._http.get<ChatRes>(`${environment.httpUrl}/last-message/`, { params })
  }
  
}
