import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';  // 新增导入

import { MatMenuModule } from '@angular/material/menu';  // 新增导入

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { CardComponent } from './card/card.component';
import { InputComponent } from './input/input.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ChatComponent } from './chat/chat.component';
import { FormsModule } from '@angular/forms';
import { HttpService } from './service/http/http.service';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { ReceivedMsgComponent } from './received-msg/received-msg.component';
import { ReceivedMsgSignComponent } from './received-msg/received-msg-sign/received-msg-sign.component';
import { ReceivedMsgLetterComponent } from './received-msg/received-msg-letter/received-msg-letter.component';
import { TestComponent } from './test/test.component';
import { ArticleHanComponent } from './article-structure/article-han/article-han.component';
import { ArticleQianComponent } from './article-structure/article-qian/article-qian.component';
import { SocketService } from './service/socket/socket.service';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomeComponent,
    CardComponent,
    InputComponent,
    SidebarComponent,
    ChatComponent,
    HomeComponent,
    ReceivedMsgComponent,
    ReceivedMsgSignComponent,
    ReceivedMsgLetterComponent,
    ArticleHanComponent,
    ArticleQianComponent,
    TestComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, 
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,  // 新增导入
    MatMenuModule,
    FormsModule,
    MatProgressBarModule,
  ],
  providers: [HttpService,SocketService],
  bootstrap: [AppComponent]
})
export class AppModule { }
