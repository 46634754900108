import { Component, Input } from '@angular/core';
import { Message } from '../service/entity/message/message.model';

@Component({
  selector: 'app-received-msg',
  templateUrl: './received-msg.component.html',
  styleUrls: ['./received-msg.component.scss']
})
export class ReceivedMsgComponent {
  @Input('message') recMessage!: Message
}
