import { Injectable, signal, computed } from '@angular/core';
import { Message, PreviewFile } from './message.model';
import { HttpService } from '../../http/http.service';
import { PostChatReq } from '../../http/http.interface';



@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private _currentSessionId = signal<string>('');
  private _messageListWS = signal<Message[]>([
    { content: '您好，我是您的會議簽呈生成小助理', sent: false, done: 1, type: 'text' }
  ]);

  currentSessionIdSignal = computed(() => this._currentSessionId());
  messageListSignal = computed(() => this._messageListWS());

  constructor(private _httpS: HttpService) {
    // this.test()
   }

  private _previewFilesToMessage(previewFileList: PreviewFile[]): Message[] {
    const messageList: Message[] = previewFileList.map(previewFile => {
      return { content: previewFile.fileName, sent: true, done: 1, type: 'file', url: previewFile.url }
    });

    return messageList
  }

  setSessionId(sessionId: string) {
    this._currentSessionId.set(sessionId);
  }

  addMessage(message: Message) {
    this._messageListWS.update((list) => [...list, message]);
  }

  postMessage(message: Message, reqType: number, previewFileList: PreviewFile[] = []) {
    //call api
    console.log("postMessage ",this._currentSessionId())

    if (!!message.content)
      this.addMessage(message)

    const payload: PostChatReq = {
      sessionId: this._currentSessionId() || "",
      content: message.content,
      type: reqType,
      fileList: previewFileList.map(pfile => pfile.file)
    };
    if (previewFileList.length != 0) {
      const fileMessageList = this._previewFilesToMessage(previewFileList);
      fileMessageList.map(message => this.addMessage(message))
    };
    this._httpS.postChat(payload)
      .subscribe(res => {
        console.log("res---> ", res)
        // this._currentSessionId.set(res.session_id);
        // if (previewFileList.length != 0) {
        //   const fileMessageList = this._previewFilesToMessage(previewFileList);
        //   fileMessageList.map(message => this.addMessage(message))
        // };

        // const receivedMsg: Message = {
        //   content: res.system.content,
        //   sent: false,
        //   done: res.system.done,
        //   type: 'text',
        //   payload: res
        // }
        // this.addMessage(receivedMsg);
      });
  }


  test() {
    const fakeMsg = {
      "session_id": "www",
      "system": {
        "content": "您好，這是產生的公文",
        "done": 1,
        "is_show_doc": true,
        "is_show_description": true,
        "is_show_progress": true,
        "is_doc_done": true,
        "doc_type": "簽",
        "doc_unit_head": "工務局新建工程處",
        "doc_code": "ABC123456",
        "doc_keep_time": "16年",
        "doc_title": "為因應本府財源拮据，擬陳行政院撥款補助新臺幣2億元，以利興建本市「○○運動中心」一案，簽請核示。",
        "doc_datetime": "中華民國000年00月00日",
        "doc_method": "先簽後搞",
        "doc_description_content": "依據「本校衛生管理委員會組織規則」及「學生膳食管理委員會組織章程」辦理。",
        "doc_description_item_list": [
          "一、為推廣市民運動休閒風氣，本府特籌劃興建一座兼具運動樂活、健康養生、休閒娛樂等多樣性的「○○運動中心」。",
          "二﹑本工程基地面積約7,300平方公尺，計畫興建地上5層、地下1層鋼骨鋼筋混凝土建築物，工程總經費為4億9,150萬元。",
          "三、「○○運動中心」將規劃為環保節能的綠建築，結合環保節能、綠能科技、資源永續循環利用、減廢、健康等理念，設計一座節能減碳與健康品質兼顧的公共建築，期達成運動中心與社區資源共享、營運互惠與公共空間管理層面雙贏的目標。",
          "四﹑因本府財源拮据，擬請行政院補助新臺幣2億元。"
        ],
        "doc_actions": "如奉 核後，函知本校各單位及附屬機構派員參加研習，全程參加人員並登錄終身學習 0 小時。"
      },
      "user": {
        "content": "我想放假",
        "type": 0,
        "done": 1
      }
    }

    const receivedMsg: Message = {
      content: fakeMsg.system.content,
      sent: false,
      done: fakeMsg.system.done,
      type: 'text',
      payload: fakeMsg
    }
    this.addMessage(receivedMsg);
  }

  fakeChatRes() {
    const fakeMsg = {
      "session_id": "www",
      "system": {
        "content": "您好，這是產生的公文",
        "done": 1,
        "is_show_doc": true,
        "doc_type": "簽",
        "doc_unit_head": "工務局新建工程處",
        "doc_code": "ABC123456",
        "doc_keep_time": "16年",
        "doc_title": "為因應本府財源拮据，擬陳行政院撥款補助新臺幣2億元，以利興建本市「○○運動中心」一案，簽請核示。",
        "doc_datetime": "中華民國000年00月00日",
        "doc_method": "先簽後搞",
        "doc_description_content": "依據「本校衛生管理委員會組織規則」及「學生膳食管理委員會組織章程」辦理。",
        "doc_description_item_list": [
          "一、為推廣市民運動休閒風氣，本府特籌劃興建一座兼具運動樂活、健康養生、休閒娛樂等多樣性的「○○運動中心」。",
          "二﹑本工程基地面積約7,300平方公尺，計畫興建地上5層、地下1層鋼骨鋼筋混凝土建築物，工程總經費為4億9,150萬元。",
          "三、「○○運動中心」將規劃為環保節能的綠建築，結合環保節能、綠能科技、資源永續循環利用、減廢、健康等理念，設計一座節能減碳與健康品質兼顧的公共建築，期達成運動中心與社區資源共享、營運互惠與公共空間管理層面雙贏的目標。",
          "四﹑因本府財源拮据，擬請行政院補助新臺幣2億元。"
        ],
        "doc_actions": "如奉 核後，函知本校各單位及附屬機構派員參加研習，全程參加人員並登錄終身學習 0 小時。"
      },
      "user": {
        "content": "我想放假",
        "type": 0,
        "done": 1
      }
    }

    return fakeMsg
  }
}
